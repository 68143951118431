import React, { Fragment } from "react";
import { Component } from "react";
import Navigation from "./template/Navigation";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import Button from "@material-ui/core/Button";
import ApiService from "./ApiService";
import { Link } from "react-router-dom";
import { Form } from "react-final-form";
import FFFile from "./components/form/fields/final-form/FFFile";
import FFSelect from "./components/form/fields/final-form/FFSelect";
import arrayMutators from "final-form-arrays";
import { Messages } from "primereact/messages";

class AssertivasForm extends Component {
  constructor(props) {
    super(props);

    this.messageRef = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);

    this.initialState = {};

    this.state = {
      initialValues: {},
    };
  }
  getHierarchicalCategoryPath(category, categorizacoes) {
    let refs = [category.descricao],
      parent;

    while (
      (parent = categorizacoes.find((item) => item.id === category.id_pai))
    ) {
      refs.push(parent.descricao);
      category = parent;
    }

    const reversedRefs = refs.reverse();
    return reversedRefs.join(" > ");
  }

  componentDidMount() {
    this.handleEdit();

    ApiService.getTiposCategorizacao().then((resp) => {
      this.setState({
        tipos: resp.data,
      });
    });

    ApiService.getCategorizacoes().then((resp) => {
      const categorizacoesWithPaths = resp.data.map((item) => ({
        ...item,
        path: this.getHierarchicalCategoryPath(item, resp.data),
      }));

      this.setState({
        categorizacoes: categorizacoesWithPaths,
      });
    });
  }

  getCategorizacaoByTipoCategorizacao(idTipoCategorizacao) {
    return this.state.categorizacoes?.filter(
      (item) => item.id_tipo_categorizacao === idTipoCategorizacao
    );
  }

  handleSubmit(values) {
    values.categorizacoes_assertivas = [
      { id_categorizacao: values.id_categorizacao, id_tipo_categorizacao: 1 },
    ];

    delete values.id_categorizacao;

    const conteudos = values.arquivo
      .map((content, index) => {
        let conteudo = {};
        conteudo.categorizacoes_assertivas = values.categorizacoes_assertivas;
        conteudo.texto = content[0];
        conteudo.justificativa = content[2];
        conteudo.assertividade =
          content[1] === "CERTO" ||
          content[1] === "CERTO." ||
          content[1] === "CERTO "
            ? 1
            : 0;
        conteudo.publico = 1;

        return conteudo;
      })
      .filter((item) => item.texto);

      for (const conteudo of conteudos) {
      ApiService.postAssertivas(conteudo);
    }

    this.messageRef.current.show({
      severity: "info",
      summary: "Sucesso!",
      detail: "Assertiva alterada.",
    });
  }

  required(value) {
    if (!value) return "Valor obrigatório";
  }

  handleEdit() {
    const id = this.props.match.params.id;

    if (id !== undefined) {
      ApiService.getAssertivasById(id).then((resp) => {
        this.setState({
          initialValues: resp.data,
        });
      });
    }
  }

  render() {
    return (
      <Fragment>
        <Navigation />
        <Container>
          <Grid container spacing={2} style={{ marginTop: "15vh" }}>
            <Grid lg={12} item display="flex">
              <Card>
                <CardHeader
                  title="Importação"
                  subheader="Importar Arquivos de Assertivas"
                  action={
                    <Button
                      variant="outlined"
                      className="buttonReturn"
                      component={Link}
                      to="/assertivas/"
                    >
                      <KeyboardReturnIcon />
                    </Button>
                  }
                />
                <CardContent>
                  <Messages
                    ref={(el) => (this.messageRef.current = el)}
                  ></Messages>
                  <Form
                    onSubmit={this.handleSubmit}
                    encType="multipart/form-data"
                    initialValues={this.state.initialValues}
                    mutators={{ ...arrayMutators }}
                    render={({
                      handleSubmit,
                      form,
                      submitting,
                      pristine,
                      values,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                          <Grid item lg={12}>
                            <FFSelect
                              id="id_categorizacao"
                              validator={this.required}
                              keyField="id"
                              textField="path"
                              options={this.state.categorizacoes}
                              title="Local da Assertiva"
                              name="id_categorizacao"
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                          <Grid item lg={12}>
                            <FFFile name="arquivo" title="Arquivo" />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                          <Grid item lg={12}>
                            <Button
                              color="primary"
                              type="submit"
                              variant="outlined"
                            >
                              Enviar
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    )}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Fragment>
    );
  }
}

export default AssertivasForm;
