import React, { Fragment } from 'react';
import { Component } from 'react';
import Navigation from './template/Navigation';
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import Button from '@material-ui/core/Button';
import ApiService from './ApiService';
import { Link } from 'react-router-dom';
import { Form } from 'react-final-form';
import FFDisabledInput from './components/form/fields/final-form/FFDisabledInput';
import { Messages } from 'primereact/messages';
import FFDisabledSelect from './components/form/fields/final-form/FFDisabledSelect';

class UsuariosForm extends Component {
    constructor(props) {
        super(props);

        this.messageRef = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            initialState : {},
            cidades : [],
            perfil : [
                {
                    id : 0,
                    descricao : 'Público'
                },
                {
                    id : 1,
                    descricao : 'Premium'
                }
            ],
            assinatura : [
                {
                    id : 0,
                    descricao : 'Desativada'
                },
                {
                    id : 1,
                    descricao : 'Ativada'
                }
            ],
            confirmado : [
                {
                    id : 0,
                    descricao : 'Não confirmado'
                },
                {
                    id : 1,
                    descricao : 'Confirmado'
                }
            ],

        }
    }

    componentDidMount() {
        this.handleEdit();

        ApiService.getCidades()
        .then((resp) => {            
            this.setState({
                cidades : resp.data
            })
        })
    }
    

    handleSubmit(values) {
        if (values.id === null) {
            ApiService.getUsuarios(values)
                .then((resp) => {
                    this.messageRef.current.show({ severity: 'info', summary: 'Sucesso!', detail: 'Usuário adicionado.' });
                    setTimeout(() => {
                        this.props.history.push('/usuarios')
                    }, 3005)
                })

        } else {
            ApiService.putUsuarios(values)
                .then((resp) => {
                    this.messageRef.current.show({ severity: 'info',  summary: 'Sucesso!', detail: 'Usuário alterado.' });
                    setTimeout(() => {
                        this.props.history.push('/usuarios')
                    }, 3005)
                })
        }

    }

    handleEdit() {
        const id = this.props.match.params.id;

        if (id !== undefined) {
            ApiService.getUsuariosById(id)
                .then(resp => {             
                    console.log(resp);                         
                    this.setState({
                        initialState : resp.data
                    })
                })
        }
    }

    required(value){
        if(!value) return 'Valor obrigatório';
    }

    render() {
        return (
            <Fragment>
                <Navigation />
                <Container>
                    <Grid container spacing={2} style={{ marginTop: '15vh' }}>
                        <Grid lg={12} item display='flex'>
                            <Card>                                
                                <CardHeader title="Usuário" subheader="Dados do usuário cadastrado"
                                    action={<Button variant="outlined" className="buttonReturn" component={Link} to='/usuarios/'>
                                        <KeyboardReturnIcon />
                                    </Button>} />
                                <CardContent>
                                <Messages ref={(el) => this.messageRef.current = el}></Messages>
                                    <Form
                                        onSubmit={this.handleSubmit}
                                        initialValues={this.state.initialState}                                     
                                        render={({ handleSubmit, form, submitting, pristine, values }) => (
                                            <form onSubmit={handleSubmit}>
                                                <Grid container spacing={1}>
                                                    <Grid item lg={6}>
                                                        <FFDisabledInput readOnly  title="Nome" name="nome"/>
                                                    </Grid>
                                                    <Grid item lg={6}>
                                                        <FFDisabledInput readOnly  title="Sobrenome" name="sobrenome"/>
                                                    </Grid>
                                                    <Grid item lg={8}>
                                                        <FFDisabledInput readOnly  title="E-mail" name="email"/>
                                                    </Grid>
                                                    <Grid item lg={4}>
                                                        <FFDisabledSelect readOnly  title="Cidade" name="id_cidade" options = {this.state.cidades } keyField="id" textField="descricao" />
                                                    </Grid>
                                                    <Grid item lg={3}>
                                                        <FFDisabledInput readOnly  title="ID" name="id"/>
                                                    </Grid>
                                                    <Grid item lg={3}>
                                                        <FFDisabledSelect readOnly  title="Perfil" name="perfil" options = {this.state.perfil } keyField="id" textField="descricao" />
                                                    </Grid>
                                                    <Grid item lg={3}>
                                                        <FFDisabledSelect title="Assinatura" name="status_assinatura" options = {this.state.assinatura } keyField="id" textField="descricao" />
                                                    </Grid>
                                                    <Grid item lg={3}>
                                                        <FFDisabledSelect readOnly  title="Confirmado" name="confirmado" options = {this.state.confirmado } keyField="id" textField="descricao" />
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        )}
                                    />

                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </Fragment>
        );
    }
}

export default UsuariosForm;