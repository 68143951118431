import React, { Fragment, Component, useRef } from "react";

const Sair = () => {

localStorage.removeItem('el-uid');
localStorage.removeItem('el-un');
localStorage.removeItem('el-ut');

window.location = '/login'

}
export default Sair;
