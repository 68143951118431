import React from 'react';
import logoEasyLegis from "../assets/easylegis.png";

const confirmNovaSenha =
    <div id="formControl">
        <h1>Algo errado!</h1>
        <p>Seu e-mail não foi encontrado na nossa relação de usuários cadastrados.</p>
        <a href='#' onClick={`window.document.history(-1)`}>Voltar</a>
    </div>;

export default confirmNovaSenha;