import axios from "axios";

//const dominio = 'http://localhost:3000/api';
const dominio = "https://api.easylegis.com.br/api";
const oDominio = "https://api.easylegis.com.br/oapi";
//const oDominio = 'http://localhost:3000/oapi';
const secret =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MiwiZW1haWwiOiJmZWxpcGUubGFlcmFAb3V0bG9vay5jb20iLCJub21lIjoiRmVsaXBlIiwic29icmVub21lIjoiTGFlcmEgSWduYWNpbyIsImlhdCI6MTU5Mjk1NzY2Nn0.UHwmeCa_EE5N7EszGJTq789UxavuGrdG8GBEm8n0FpY";

const ApiDashboard = {
  getUsuariosCount: () =>
    axios
      .get(`${dominio}/usuarios/paid-free-count`, {
        headers: {
          authorization: secret,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response;
      }),

  getUsuariosStopPaying: () =>
    axios
      .get(`${dominio}/usuarios/stop-paying/`, {
        headers: {
          authorization: secret,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response;
      }),
};

export default ApiDashboard;
