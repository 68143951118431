import React, { memo } from 'react'
import { Field } from 'react-final-form';

/**Componente para renderizar um container unificado para seus inputs. */
function FFContainer({ component: Component, name, title, validator, ...componentProps }) {
  return (
    <Field name={name} validate={validator} >
      {
        ({ input, meta }) => (
          <div className={`form-group`}>
            <label>{title}</label>
            <Component {...input} {...componentProps} />
            {
              meta.error &&
              <div style={{ display: 'block' }} className="invalid-feedback">
                {meta.error}
              </div>
            }
          </div>
        )
      }
    </Field>
  )
}

export default memo(FFContainer);