import React from 'react';
import { Component } from "react";
import { Form } from "react-final-form";

import logoEasyLegis from "../assets/easylegis.png";
import "./trocarSenha.css";
import formEmailTrocaSenha from './FormEmailTrocaSenha';
import confirmNovaSenha from './ConfirmNovaSenha';
import usuarioNaoEncontrado from './USuarioNaoEncontrado';
import { useState } from 'react';
import { useEffect } from 'react';

import ApiService from "../ApiService";

class TrocarSenha extends Component {
    constructor() {
        super();
        this.state = {
            enviado: false,
            tela: formEmailTrocaSenha
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(value) {
        try {
            ApiService.changePasswordRequest(value).then(resp => {
                if (resp.status === 200) {
                    this.setState({
                        tela: confirmNovaSenha
                    })
                }
            })
        } catch (e) {
            console.error(e);
            console.info('entrou');
            this.setState({
                tela: usuarioNaoEncontrado
            })
        }
    }

    render() {
        return (
            <div id="container">
                <div id="boxForm">

                    <img src={logoEasyLegis} id="imgLogo" />

                    <div id="formControl">
                        <Form
                            onSubmit={this.handleSubmit}
                            initialValues={this.state}
                            render={({
                                handleSubmit,
                                form,
                                submitting,
                                pristine,
                                values,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    {this.state.tela}
                                </form>
                            )}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default TrocarSenha;