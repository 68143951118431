import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import TiposCategorizacao from './TiposCategorizacao';
import TiposCategorizacaoForm from './TiposCategorizacaoForm';
import Categorizacoes from './Categorizacoes';
import CategorizacoesForm from './CategorizacoesForm';
import Assertivas from './Assertivas';
import AssertivasForm from './AssertivasForm';
import Usuarios from './Usuarios';
import UsuariosForm from './UsuariosForm';
import Admin from './Admin';
import Erratas from './Erratas';
import ImportacaoForm from './ImportacaoForm';
import Login from './Login';
import Sair from './Sair';
import Mensagens from './Mensagens';
import Parametros from './Parametros';
import ParametrosForm from './ParametrosForm';
import ErratasForm from './ErratasForm';
import TrocarSenha from './TrocarSenha/TrocarSenha';
import NovaSenha from './TrocarSenha/NovaSenha';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import 'primereact/resources/themes/saga-orange/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import './login.css';


const authorized = localStorage.getItem('el-ut');

ReactDOM.render(  
  <BrowserRouter basename="/">    
    <Switch>      
      <Route path="/" exact={true} component={(authorized !== null) ? App : Login} />      
      <Route path="/trocar-senha" exact={true} component={ TrocarSenha } />    
      <Route path="/nova-senha/:token" exact={true} component={ NovaSenha } /> 
      <Route path="/tipos-categorizacao" exact={true} component={(authorized !== null) ? TiposCategorizacao : Login} />
      <Route path="/tipos-categorizacao/cadastro" exact={true} component={(authorized !== null) ? TiposCategorizacaoForm : Login} />
      <Route path="/tipos-categorizacao/edicao/:id" exact={true} component={(authorized !== null) ? TiposCategorizacaoForm : Login} />
      <Route path="/categorizacoes" exact={true} component={(authorized !== null) ? Categorizacoes : Login} />
      <Route path="/categorizacoes/cadastro" exact={true} component={(authorized !== null) ? CategorizacoesForm : Login} />
      <Route path="/categorizacoes/edicao/:id" exact={true} component={(authorized !== null) ? CategorizacoesForm : Login} />
      <Route path="/assertivas" exact={true} component={(authorized !== null) ? Assertivas : Login} />
      <Route path="/assertivas/cadastro" exact={true} component={(authorized !== null) ? AssertivasForm : Login} />
      <Route path="/assertivas/cadastro/:id_categoria" exact={true} component={(authorized !== null) ? AssertivasForm : Login} />
      <Route path="/assertivas/edicao/:id" exact={true} component={(authorized !== null) ? AssertivasForm : Login} />
      <Route path="/usuarios" exact={true} component={(authorized !== null) ? Usuarios : Login} />
      <Route path="/usuarios/edicao/:id" exact={true} component={(authorized !== null) ? UsuariosForm : Login} />
      <Route path="/erratas" exact={true} component={(authorized !== null) ? Erratas : Login} />
      <Route path="/erratas/:id" exact={true} component={(authorized !== null) ? ErratasForm : Login} />
      <Route path="/mensagens" exact={true} component={(authorized !== null) ? Mensagens : Login} />
      <Route path="/importacao" exact={true} component={(authorized !== null) ? ImportacaoForm : Login} />
      <Route path="/parametros" exact={true} component={(authorized !== null) ? Parametros : Login} />
      <Route path="/parametros/edicao/:id" exact={true} component={(authorized !== null) ? ParametrosForm : Login} />
      <Route path="/admin" exact={true} component={(authorized !== null) ? Admin : Login} />
      <Route path="/login" exact={true} component={Login} />      
      <Route path="/sair" exact={true} component={Sair} />    
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
