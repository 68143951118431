import React from 'react';
import { InputSwitch } from 'primereact/inputswitch';

export default function Switch({ value, onChange, onBlur, onFocus }) {
  return (
    <InputSwitch checked={value || false} 
    style={{ display: 'block' }} 
    onChange={event => onChange(event.value)}
    onFocus={onFocus}
    onBlur={onBlur}
    />
  )
}
