import React, { Fragment } from "react";
import { Component } from "react";
import Navigation from "./template/Navigation";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import Button from "@material-ui/core/Button";
import ApiService from "./ApiService";
import { Link } from "react-router-dom";
import { Form } from "react-final-form";
import FFSelect from "./components/form/fields/final-form/FFSelect";
import arrayMutators from "final-form-arrays";
import FFJodit from "./components/form/fields/final-form/FFJodit";
import FFSwitch from "./components/form/fields/final-form/FFSwitch";
import { Messages } from "primereact/messages";

const joditConfig = {
  buttons: ["bold", "italic", "underline", "ul", "ol", "align"],
};

class AssertivasForm extends Component {
  constructor(props) {
    super(props);

    this.messageRef = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);

    this.initialState = {};

    this.state = {
      initialValues: {},
    };
  }

  getHierarchicalCategoryPath(category, categorizacoes) {
    let refs = [category.descricao],
      parent;

    while (
      (parent = categorizacoes.find((item) => item.id === category.id_pai))
    ) {
      refs.push(parent.descricao);
      category = parent;
    }

    const reversedRefs = refs.reverse();
    return reversedRefs.join(" > ");
  }

  componentDidMount() {
    const idCategoria = this.props.match.params.id_categoria;

    if (idCategoria) {
      this.setState((prevState) => {
        let initialValues = Object.assign({}, prevState.initialValues);
        initialValues.id_categorizacao = Number(idCategoria);

        return { initialValues };
      });
    }

    this.handleEdit();

    ApiService.getCategorizacoes().then((resp) => {
      const categorizacoesWithPaths = resp.data.map((item) => ({
        ...item,
        path: this.getHierarchicalCategoryPath(item, resp.data),
      }));

      this.setState({
        categorizacoes: categorizacoesWithPaths,
      });
    });
  }

  getCategorizacaoByTipoCategorizacao(idTipoCategorizacao) {
    return this.state.categorizacoes?.filter(
      (item) => item.id_tipo_categorizacao === idTipoCategorizacao
    );
  }

  handleSubmit(values) {
    values.categorizacoes_assertivas = [
      { id_categorizacao: values.id_categorizacao, id_tipo_categorizacao: 1 },
    ];
    
    delete values.id_categorizacao;

    if (values.id === null) {
      ApiService.postAssertivas(values);
      this.messageRef.current.show({
        severity: "info",
        summary: "Sucesso!",
        detail: "Assertiva adicionada.",
      });
    } else {
      ApiService.putAssertivas(values);
      this.messageRef.current.show({
        severity: "info",
        summary: "Sucesso!",
        detail: "Assertiva alterada.",
      });
      setTimeout(() => {
        this.props.history.push("/assertivas");
      }, 3005);
    }
  }

  required(value) {
    if (!value) return "Valor obrigatório";
  }

  handleEdit() {
    const id = this.props.match.params.id;

    if (id !== undefined) {
      ApiService.getAssertivasById(id).then((resp) => {
        console.log(resp);
        this.setState({
          initialValues: resp.data,
        });

        this.setState((prevState) => {
          let initialValues = Object.assign({}, prevState.initialValues);
          initialValues.id_categorizacao = Number(resp.data.categorizacoes_assertivas[0].id_categorizacao);

          return { initialValues };
        });
      });
    }
  }

  render() {
    return (
      <Fragment>
        <Navigation />
        <Container>
          <Grid container spacing={2} style={{ marginTop: "15vh" }}>
            <Grid lg={12} item display="flex">
              <Card>
                <CardHeader
                  title="Assertivas"
                  subheader="Assertivas do aplicativo"
                  action={
                    <Button
                      variant="outlined"
                      className="buttonReturn"
                      component={Link}
                      to="/assertivas/"
                    >
                      <KeyboardReturnIcon />
                    </Button>
                  }
                />
                <CardContent>
                  <Messages
                    ref={(el) => (this.messageRef.current = el)}
                  ></Messages>
                  <Form
                    onSubmit={this.handleSubmit}
                    initialValues={this.state.initialValues}
                    mutators={{ ...arrayMutators }}
                    render={({
                      handleSubmit,
                      form,
                      submitting,
                      pristine,
                      values,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                          <Grid item lg={10}>
                            <FFSelect
                              id="id_categorizacao"
                              validator={this.required}
                              keyField="id"
                              textField="path"
                              options={this.state.categorizacoes}
                              title="Local da Assertiva"
                              name="id_categorizacao"
                            />
                          </Grid>
                          <Grid item lg={1}>
                            <FFSwitch
                              name="assertividade"
                              title="Está correta?"
                            />
                          </Grid>
                          <Grid item lg={1}>
                            <FFSwitch name="publico" title="É pública?" />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                          <Grid item lg={12}>
                            <FFJodit
                              name="texto"
                              title="Assertiva"
                              id="texto"
                              config={joditConfig}
                            />
                          </Grid>
                          <Grid item lg={12}>
                            <FFJodit
                              name="justificativa"
                              title="Justificativa"
                              id="justificativa"
                              config={joditConfig}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                          <Grid item lg={12}>
                            <Button
                              color="primary"
                              type="submit"
                              variant="outlined"
                            >
                              Enviar
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    )}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Fragment>
    );
  }
}

export default AssertivasForm;
