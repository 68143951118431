import React, { Fragment } from "react";
import { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Navigation from "./template/Navigation";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import AddBoxIcon from "@material-ui/icons/AddBox";
import Button from "@material-ui/core/Button";
import { InputText } from "primereact/inputtext";
import ApiService from "./ApiService";
import { Link } from "react-router-dom";
import SettingsIcon from "@material-ui/icons/Settings";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

class TiposCategorizacao extends Component {
  constructor(props) {
    super(props);

    this.state = {
      assertivas: {},
      globalFilter: null,
      deleteDialogOpen: false,
      idDelete: null,
    };
  }

  getHierarchicalCategories(categorizacoes, pai = null) {
    return categorizacoes
      .filter((item) => item.id_pai === pai)
      .map((item) => ({
        key: `${pai}-${item.id}`,
        data: {
          descricao: item.descricao,
          id: item.id,
        },
        children: this.getHierarchicalCategories(categorizacoes, item.id),
      }));
  }

  componentDidMount() {
    ApiService.getAssertivas().then((response) => {
      console.log(response);
      const resp = response.data.map((registro, chave) => {
        const visibilidade = registro.publico === 1? "Pública" : "Privada";
        const assertividade =
          registro.assertividade === 1 ? "Verdadeira" : "Falsa";
        const contador = chave + 1;

        return { ...registro, visibilidade, assertividade, contador };
      });

      this.setState({
        assertivas: resp,
      });
    });
  }

  setToDelete(id) {
    this.setState({
      deleteDialogOpen: true,
      idDelete: id,
    });
  }

  processDelete() {
    ApiService.deleteAssertivas(this.state.idDelete).then((response) => {
      this.setState({
        idDelete: null,
        deleteDialogOpen: false,
      });

      this.componentDidMount();
    });
  }

  render() {
    const header = (
      <div style={{ textAlign: "left" }}>
        <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
        <InputText
          type="Buscar"
          onInput={(e) => this.setState({ globalFilter: e.target.value })}
          placeholder="Digite algo para pesquisar"
          style={{ width: "95%" }}
        />
      </div>
    );
    return (
      <Fragment>
        <Navigation />

        <Dialog
          open={this.state.deleteDialogOpen}
          onClose={() => this.setState({ deleteDialogOpen: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirma a deleção do registro?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Você tem certeza que deseja excluir o registro{" "}
              {this.state.idDelete} ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.processDelete()} color="primary">
              Sim, tenho certeza.
            </Button>
            <Button
              onClick={() =>
                this.setState({ deleteDialogOpen: false, idDelete: null })
              }
              color="primary"
              autoFocus
            >
              Cancelar.
            </Button>
          </DialogActions>
        </Dialog>

        <Container>
          <Grid container spacing={2} style={{ marginTop: "15vh" }}>
            <Grid lg={12} item display="flex">
              <Card>
                <CardHeader
                  title="Assertivas"
                  subheader="Assertivas cadastradas no sistema"
                  action={
                    <Button
                      variant="outlined"
                      className="buttonAdd"
                      component={Link}
                      to="/assertivas/cadastro"
                    >
                      <AddBoxIcon />
                    </Button>
                  }
                />
                <CardContent>
                  <DataTable
                    value={this.state.assertivas}
                    paginator={true}
                    rows={10}
                    header={header}
                    globalFilter={this.state.globalFilter}
                    emptyMessage="Nenhum registro encontrado"
                  >
                    <Column field="id" header="ID" className="columnId" />
                    <Column
                      field="contador"
                      sortable={true}
                      header="#"
                      className="columnId"
                    />
                    <Column
                      field="texto"
                      sortable={true}
                      header="Descrição"
                      body={(row) =>
                        String(row.texto).replace(/(<([^>]+)>)/gi, "")
                      }
                    />
                    <Column
                      field="justificativa"
                      sortable={true}
                      header="Justificativa"
                      body={(row) =>
                        String(row.justificativa).replace(/(<([^>]+)>)/gi, "")
                      }
                    />
                    <Column
                      field="assertividade"
                      sortable={true}
                      header="Assertividade"
                      style={{ width: "10%" }}
                    />
                    <Column
                      field="visibilidade"
                      sortable={true}
                      header="Visibilidade"
                      style={{ width: "10%" }}
                    />
                    <Column
                      field="opcoes"
                      header="Opções"
                      className="columnOptions"
                      body={(row) => {
                        return (
                          <div>
                            <Button
                              size="small"
                              variant="outlined"
                              color="default"
                              component={Link}
                              to={`/assertivas/edicao/${row.id}`}
                            >
                              <SettingsIcon />
                            </Button>
                            <Button
                              size="small"
                              variant="outlined"
                              className="buttonRemove"
                              onClick={(e) => this.setToDelete(row.id)}
                            >
                              <DeleteIcon color="secondary"/>
                            </Button>
                          </div>
                        );
                      }}
                    />
                  </DataTable>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Fragment>
    );
  }
}

export default TiposCategorizacao;
