import React, { Fragment } from "react";
import { Component } from "react";
import Navigation from "./template/Navigation";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import Button from "@material-ui/core/Button";
import ApiService from "./ApiService";
import { Link } from "react-router-dom";
import { Form } from "react-final-form";
import FFInput from "./components/form/fields/final-form/FFInput";
import FFSelect from "./components/form/fields/final-form/FFSelect";
import { Messages } from "primereact/messages";

class TiposCategorizacao extends Component {
  constructor(props) {
    super(props);

    this.messageRef = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);

    this.initialState = {
      descricao: null,
      id_tipo: null,
      id_pai: null,
      id: null,
      ativo: 1,
      tipos: [],
      categorizacoes: [],
    };

    this.state = this.initialState;
  }

  getHierarchicalCategoryPath(category, categorizacoes) {
    let refs = [category.descricao],
      parent;

    while (
      (parent = categorizacoes.find((item) => item.id === category.id_pai))
    ) {
      refs.push(parent.descricao);
      category = parent;
    }

    const reversedRefs = refs.reverse();
    return reversedRefs.join(" > ");
  }

  componentDidMount() {
    this.handleEdit();

    // ApiService.getTiposCategorizacao().then((resp) => {
    //   this.setState({
    //     tipos: resp.data,
    //   });
    // });

    ApiService.getCategorizacoes().then((resp) => {
      const categorizacoesWithPaths = resp.data.map((item) => ({
        ...item,
        path: this.getHierarchicalCategoryPath(item, resp.data),
      }));

      this.setState({
        categorizacoes: categorizacoesWithPaths,
      });
    });
  }

  handleSubmit(values) {
    if (values.id === null) {

      values.categorizacoes_assertivas = [
        { id_categorizacao: values.id_categorizacao, id_tipo_categorizacao: 1 },
      ];

      values.id_tipo = 1;

      ApiService.postCategorizacoes(values);
      this.messageRef.current.show({
        severity: "info",
        summary: "Sucesso!",
        detail: "Categorização adicionada.",
      });
      setTimeout(() => {
        this.props.history.push("/categorizacoes");
      }, 3005);
    } else {
      
      values.categorizacoes_assertivas = [
        { id_categorizacao: values.id_categorizacao, id_tipo_categorizacao: 1 },
      ];
      values.id_tipo = 1;

      ApiService.putCategorizacoes(values);
      this.messageRef.current.show({
        severity: "info",
        summary: "Sucesso!",
        detail: "Categorização alterada.",
      });
      setTimeout(() => {
        this.props.history.push("/categorizacoes");
      }, 3005);
    }
  }

  required(value) {
    if (!value) return "Valor obrigatório";
  }

  handleEdit() {
    const id = this.props.match.params.id;

    if (id !== undefined) {
      ApiService.getCategorizacoesById(id).then((resp) => {
        console.log(resp);
        this.setState({
          descricao: resp.data.descricao,
          id: resp.data.id,
          id_pai: resp.data.id_pai,
          id_tipo: resp.data.id_tipo,
        });
      });
    }
  }

  render() {
    return (
      <Fragment>
        <Navigation />

        <Container>
          <Grid container spacing={2} style={{ marginTop: "15vh" }}>
            <Grid lg={12} item display="flex">
              <Card>
                <CardHeader
                  title="Categorização"
                  subheader="Categorizações cadastrados no sistema"
                  action={
                    <Button
                      variant="outlined"
                      className="buttonReturn"
                      component={Link}
                      to="/categorizacoes/"
                    >
                      <KeyboardReturnIcon />
                    </Button>
                  }
                />
                <CardContent>
                  <Messages
                    ref={(el) => (this.messageRef.current = el)}
                  ></Messages>
                  <Form
                    onSubmit={this.handleSubmit}
                    initialValues={this.state}
                    render={({
                      handleSubmit,
                      form,
                      submitting,
                      pristine,
                      values,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                          {/* <Grid item lg={12}>
                            <FFSelect
                              id="id_tipo"
                              validator={this.required}
                              keyField="id"
                              textField="descricao"
                              options={this.state.tipos}
                              title="Tipo de Categorização"
                              name="id_tipo"
                            />
                          </Grid> */}
                          <Grid item lg={12}>
                            <FFSelect
                              id="id_pai"
                              keyField="id"
                              textField="path"
                              options={this.state.categorizacoes}
                              title="Categorização Pai"
                              name="id_pai"
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                          <Grid item lg={12}>
                            <FFInput
                              id="descricao"
                              validator={this.required}
                              title="Descrição"
                              name="descricao"
                            />
                          </Grid>
                          <Grid item lg={12}>
                            <Button
                              color="primary"
                              type="submit"
                              variant="outlined"
                            >
                              Enviar
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    )}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Fragment>
    );
  }
}

export default TiposCategorizacao;
