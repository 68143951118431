import React from 'react';
import { Dropdown } from 'primereact/dropdown';

export default function Select({ options, keyField = "key", textField = "text", ...rest }) {
  return (
    <Dropdown
      options={options}
      optionLabel={textField}
      optionValue={keyField}
      placeholder="Selecione um item..."
      {...rest}
      style={{ width: '100%' }}
      disabled={true}
    />
  )
}
