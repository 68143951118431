import React from 'react'

const TextReaderInput = ({ onChange }) => {
  function parseText(text) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, 'text/html');
    const p = doc.querySelectorAll('p');

    let i = 0;
    let j = 0;
    const conjunto = [];
    conjunto[j] = [];
    
    for (let par of p) {
      
      let content = par.innerHTML;
      //content = content.replaceAll("   ", " ");
      content = content.replaceAll(/\r?\n|\r|\n/g, " ");
      content = content.replaceAll(/style=(".*?"|'.*?'|[^"'][^\s]*)/gs, "");
      content = content.replaceAll("<span >&nbsp;</span>", "");
      content = content.replaceAll("<b></b>", "");
            
      console.info(content);

      let text = par.textContent;
      text = text.trim();
      
      //text = text.replaceAll("   ", " ");
      //text = text.replaceAll(/\n+/g, " "); 
      text = text.replaceAll(/style=(".*?"|'.*?'|[^"'][^\s]*)/gs, "");     
      text = text.replaceAll("<span >&nbsp;</span>", "");
      text = text.replaceAll("<b></b>", "");
      
            
      if(text === ''){
        i = 0;        
      }else{
        if(i !== 1){
          conjunto[j][i] = content;
        }else{
          conjunto[j][i] = text;
        }

        if (i === 2) {
          i = 0;
          j++;
          conjunto[j] = [];
        } else {
          i++;
        }        
      }
    }

    onChange(conjunto);
  }

  function loadFile(event) {
    event.preventDefault();
    const fileReader = new FileReader();
    fileReader.onload = (event) => parseText(event.target.result);
    fileReader.readAsText(event.target.files[0],'ISO-8859-1');
  }

  return (
    <input type="file" className="form-control" onChange={loadFile} />
  )
}

export default TextReaderInput;

// export default function File({ value, onChange }) {
//   return (
//     <input type="file" className="form-control" value={value} onChange={onChange} />
//   )
// }
