import React from 'react';
import FFInput from '../components/form/fields/final-form/FFInput';
import { Card, Button, Col, Row } from "react-bootstrap";

const formEmailTrocaSenha = <div id="formGroup">
    <FFInput
        title="Informe o seu E-mail para continuar"
        name="email"
    />

    <Button type="submit" id="botaoLogin">
        Enviar e-mail
    </Button>
</div>

export default formEmailTrocaSenha