import React, { Fragment } from "react";
import { Component } from "react";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import Navigation from "./template/Navigation";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import AddBoxIcon from "@material-ui/icons/AddBox";
import Button from "@material-ui/core/Button";
import ApiService from "./ApiService";
import { InputText } from "primereact/inputtext";
import { Link } from "react-router-dom";
import SettingsIcon from "@material-ui/icons/Settings";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import QueuePlayNextIcon from "@material-ui/icons/QueuePlayNext";

class TiposCategorizacao extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categorizacoes: {},
      globalFilter: null,
      deleteDialogOpen: false,
      idDelete: null,
    };
  }

  getHierarchicalCategories(categorizacoes, pai = null) {
    return categorizacoes
      .filter((item) => item.id_pai === pai)
      .map((item) => ({
        key: `${pai}-${item.id}`,
        data: {
          descricao: item.descricao,
          id: item.id,
        },
        children: this.getHierarchicalCategories(categorizacoes, item.id),
      }));
  }

  componentDidMount() {
    ApiService.getTiposCategorizacao().then((tiposCategorizacao) => {
      ApiService.getCategorizacoes().then((results) => {
        let categorizacoes;
        categorizacoes = results.data.map((item) => ({
          ...item,
          tipoCategorizacao: tiposCategorizacao.data.find(
            (tipoCategorizacaoItem) => tipoCategorizacaoItem.id === item.id_tipo
          ),
        }));

        const dados = this.getHierarchicalCategories(categorizacoes);
        //console.log(dados);
        this.setState({
          categorizacoes: dados,
        });
      });
    });
  }

  setToDelete(id) {
    this.setState({
      deleteDialogOpen: true,
      idDelete: id,
    });
  }

  processDelete() {
    ApiService.deleteCategorizacoes(this.state.idDelete).then((response) => {
      this.setState({
        idDelete: null,
        deleteDialogOpen: false,
      });

      this.componentDidMount();
    });
  }

  render() {
    const header = (
      <div style={{ textAlign: "left" }}>
        <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
        <InputText
          type="Buscar"
          onInput={(e) => this.setState({ globalFilter: e.target.value })}
          placeholder="Digite algo para pesquisar"
          style={{ width: "95%" }}
        />
      </div>
    );

    return (
      <Fragment>
        <Navigation />

        <Dialog
          open={this.state.deleteDialogOpen}
          onClose={() => this.setState({ deleteDialogOpen: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirma a deleção do registro?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Você tem certeza que deseja excluir o registro{" "}
              {this.state.idDelete} ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.processDelete()} color="primary">
              Sim, tenho certeza.
            </Button>
            <Button
              onClick={() =>
                this.setState({ deleteDialogOpen: false, idDelete: null })
              }
              color="primary"
              autoFocus
            >
              Cancelar.
            </Button>
          </DialogActions>
        </Dialog>

        <Container>
          <Grid container spacing={2} style={{ marginTop: "15vh" }}>
            <Grid lg={12} item display="flex">
              <Card>
                <CardHeader
                  title="Categorizações"
                  subheader="Categorizações cadastradas no sistema"
                  action={
                    <Button
                      variant="outlined"
                      className="buttonAdd"
                      component={Link}
                      to="/categorizacoes/cadastro"
                    >
                      <AddBoxIcon />
                    </Button>
                  }
                />
                <CardContent>
                  <TreeTable
                    value={this.state.categorizacoes}
                    paginator={true}
                    rows={25}
                    header={header}
                    globalFilter={this.state.globalFilter}
                    emptyMessage="Nenhum registro encontrado"
                  >
                    <Column field="id" header="ID" className="columnId" />
                    <Column
                      field="descricao"
                      key="key"
                      header="Descrição"
                      expander
                    />
                    <Column
                      field="opcoes"
                      header="Opções"
                      style={{ width: "22%" }}
                      body={(row) => {                        
                        return (
                          <div>
                            <Button
                              size="small"
                              variant="outlined"
                              color="default"
                              component={Link}
                              to={`/assertivas/cadastro/${row.data.id}`}
                            >
                              <QueuePlayNextIcon />
                            </Button>
                            <Button
                              size="small"
                              variant="outlined"
                              color="default"
                              component={Link}
                              to={`/categorizacoes/edicao/${row.data.id}`}
                            >
                              <SettingsIcon />
                            </Button>
                            <Button
                              size="small"
                              variant="outlined"
                              className="buttonRemove"
                              onClick={(e) => this.setToDelete(row.data.id)}
                            >
                              <DeleteIcon color="secondary" />
                            </Button>
                          </div>
                        );
                      }}
                    />
                  </TreeTable>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Fragment>
    );
  }
}

export default TiposCategorizacao;
